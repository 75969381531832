import React from "react";

import eventBus from "@/app/app-event-bus.mjs";
import {
  CURR_SEASON_PROMO,
  EVENT_SEASON_REVIEW_PROMO_CLICK,
  MIN_QUEUE_GAMES,
  SUPPORTED_QUEUES,
} from "@/game-lol/constants/season-review.mjs";
import { Promo } from "@/game-lol/SeasonReview.jsx";
import { useSeasonAggregate } from "@/game-lol/utils/season-review.mjs";

function SeasonReviewPromo({ region, name, profile }) {
  const seasonReviewStats = useSeasonAggregate(region, name, CURR_SEASON_PROMO);
  const hasEnoughGames =
    seasonReviewStats.totalGames >= MIN_QUEUE_GAMES * SUPPORTED_QUEUES.length;

  if (!hasEnoughGames) return null;

  return (
    <Promo
      profile={profile}
      link={`/lol/season-review/${region}/${name}/13`}
      onClickLink={() => {
        eventBus.emit(EVENT_SEASON_REVIEW_PROMO_CLICK, {
          isModal: false,
          url: `/lol/season-review/${region}/${name}/13`,
        });
      }}
    />
  );
}

export default SeasonReviewPromo;
